<template lang="pug">
  div
    v-layout.password_restore(align-center, justify-center, column, fill-height)
      .errors(v-if="restoreErrors && restoreErrors.length")
        .errors__item(v-for="error in restoreErrors", :key="error.title") #[strong {{ error.title }}] #[span(v-html="error.detail")]
      v-form(ref="form", v-model="valid", @submit.prevent="")
        .password_restore-language.d-flex.align-center.justify-end
          LanguageSwitching
        div(v-show="!showCaptchaDialog")
          v-layout.password_restore-layout(column)
            h2 {{ $t('actions.restorePassword') }}
            .password_restore-email
              div {{ $t('formFields.email') }}
              v-text-field(
              :label="$t('formFields.email')",
              v-model.trim="email",
              :rules="[rules.required, rules.email]",
              required)
            .errors(v-if="errors.length")
              .errors__item(v-for="error in errors", :key="error.title") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
            v-btn.password_restore-button(:disabled="!valid || countdownActive", @click="proceedToCaptchaForm", type="submit") {{ $t('actions.proceed') }}
          .recovery_timer(v-if="countdownActive")
            p(v-html="$t('messages.success.recoveryEmailSent2', { email: recoverySentEmail })")
            .timer {{ $t('messages.inform.timeLeftToRequestRestoreEmal') }}
              Countdown(:timer="fiveMinInMs", :timerStart="recoverySentTimestamp", @expired="countdownActive = false")
        v-card(v-if="showCaptchaDialog")
          //.close(@click="showCaptchaDialog = false")
            SvgPlus
          v-card-title {{ $t('formFields.captcha') }}
          v-card-text
            v-form(ref="captchaForm", v-model="captchaValid", @submit.prevent="")
              v-layout.captcha(v-if="captchaImage", align-center)
                img(v-if="!loadingNewCaptcha", :src="captchaImage")
                v-icon.reload(v-if="!loadingNewCaptcha", @click="getNewCaptchaImage") mdi-reload
              span.captcha__info {{ $t('hints.captcha') }}
              v-text-field(
                v-model="captcha",
                ref="captchaInput",
                :rules="[rules.required]",
                :label="$t('formFields.captcha')",
                :persistent-hint="true",
                autocomplete="off",
                required)
              .errors(v-if="errorsCaptcha")
                p(v-for="error in errorsCaptcha", :key="error.title") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
              v-btn(:disabled="!captchaValid || loading", @click="sendEmail", type="submit") {{ $t('actions.proceed') }}
</template>

<script>
import Countdown from '@/components/Countdown';
import REGEXP from '@/regexp';
import store from '@/store/store';
import SvgPlus from '@/components/svg/SvgPlus';
import LanguageSwitching from '@/components/LanguageSwitching';

export default {
  components: { Countdown, SvgPlus, LanguageSwitching },
  data() {
    return {
      valid: false,
      loading: false,
      loadingNewCaptcha: false,
      captchaValid: false,
      success: false,
      errors: [],
      errorsCaptcha: [],
      email: '',
      countdownActive: false,
      fiveMinInMs: 5 * 60 * 1000,
      captcha: '',
      showCaptchaDialog: false
    };
  },
  methods: {
    proceedToCaptchaForm() {
      this.valid = false;

      this.$store.dispatch('user/getCaptchaImage', this.captchaId)
        .then(() => {
          this.showCaptchaDialog = true;
        })
        .catch(errors => console.log('Error in fetch captcha image', errors))
        .finally(() => { this.valid = true; });
    },
    sendEmail() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const data = {
          data: {
            type: 'recovery-passwords',
            attributes: {
              email: this.email,
              captcha_id: this.captchaId,
              captcha_code: this.captcha
            }
          }
        };

        this.$store.dispatch('user/sendRecoveryEmail', data)
          .then(() => {
            this.success = true;
            this.errors = [];
            this.loading = false;
          })
          .catch(errors => {
            const hasCaptchaError = errors.some(error => error.title.includes('captcha') || error.detail.includes('captcha'));

            if (hasCaptchaError) {
              this.errorsCaptcha = errors;
              this.getNewCaptchaImage();
            } else {
              this.errors = errors;
            }
          })
          .finally(() => { this.loading = false; });
      }
    },
    getNewCaptchaImage() {
      if (this.loadingNewCaptcha) return;

      this.loadingNewCaptcha = true;

      this.$store.dispatch('user/getCaptchaImage', this.captchaId)
        .then(() => {
          if (this.showCaptchaDialog) {
            this.captcha = '';

            setTimeout(() => {
              this.$refs.captchaForm.resetValidation();
              this.$refs.captchaInput.focus();
            },
            0);
          }
        })
        .catch(errors => console.log('Error in fetch captcha image', errors))
        .finally(() => { this.loadingNewCaptcha = false; });
    }
  },
  watch: {
    success(val) {
      if (val) { this.$router.push('/restore-confirm'); }
    },
    recoverySentTimestamp(val) {
      if (val) this.countdownActive = true;
    },
    showCaptchaDialog(val) {
      if (val) {
        setTimeout(() => this.$refs.captchaInput.focus(), 0);
      } else {
        this.errorsCaptcha = [];
        this.$refs.captchaForm.resetValidation();
        // this.getNewCaptchaImage();
      }
    }
  },
  computed: {
    recoverySentTimestamp() {
      return this.$store.state.user.recoverySentTimestamp;
    },
    recoverySentEmail() {
      return this.$store.state.user.recoverySent;
    },
    rules() {
      return {
        required: v => !!v.trim() || this.$t('validation.required'),
        email: v => REGEXP.email.test(v.trim()) || this.$t('validation.emailInvalid')
      };
    },
    captchaId() {
      return this.$store.state.user.captchaId;
    },
    captchaImage() {
      return this.$store.state.user.captchaImage;
    },
    restoreErrors() {
      return this.$store.state.user.resetPasswordHashErrors;
    }
  },
  mounted() {
    this.$store.dispatch('user/setImgName', 'signIn');

    if (this.recoverySentTimestamp && (Date.now() - this.recoverySentTimestamp) < this.fiveMinInMs) {
      this.countdownActive = true;
    }

    if (!this.captchaId) {
      this.$store.dispatch('user/getCaptchaId');
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('user/getCaptchaId');

    next();
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .password_restore {
    padding-bottom: 40px;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 530px) {
      max-width: 100%;
    }
    &-language{
      @media screen and (max-width: 530px) {
        border-bottom: 1px solid rgba(31, 33, 38, 0.1);
        padding:  0 20px 14px;
      }
    }
    &-layout{
      @media screen and (max-width: 530px) {
        padding:  0 20px;
      }
    }
    h2 {
      font-size: 36px;
      color: $black2;
      margin-top: 199px;
      margin-bottom: 30px;
      @media screen and (max-width: 530px) {
        margin: 20px 0;
        text-align: left;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
      }
    }
    &-email{
      margin-bottom: 10px;
      >div{
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: rgba(31, 33, 38, 0.6);
        text-align: left;
      }
    }
    ::v-deep .v-text-field .v-label{
      opacity: 0;
    }
    ::v-deep .v-text-field .v-input__slot{
      height: 45px;
      padding-top: 5px;
    }
    &-button{
      background: #0071E3!important;
      border-radius: 5px;
      width: 100%;
      height: 40px !important;
    }
    .v-form { width: 100%; }
    .forgot_link { margin-top: 20px; }
    .timer { margin: 10px 0;}
  }

  .errors {
    width: 100%;
    color: $error-color;
  }

  ::v-deep .v-card__text {
    text-align: left !important;
    padding: 0;
    width: 100%;
  }

  .v-card {

    .close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      outline: none;
      transform: rotate(45deg);
      margin: auto 0 auto auto;
      opacity: 0.54;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    ::v-deep .v-btn,
    ::v-deep .v-input {
      width: 260px;
    }
  }

  .recovery_timer { margin-top: 25px; }

  .v-card {
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 530px) {
      padding: 0 20px;
    }
    .captcha {
      margin-bottom: 15px;
      position: relative;
      @media screen and (max-width: 530px) {
        margin-bottom: 0;
      }

      img {
         width: 360px;
         margin-right: 0;
         @media screen and (max-width: 530px) {
           width: 280px;
         }
       }

      button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .captcha__info {
      margin-top: 1vh;
      width: 360px;
      text-align: left;
      margin-bottom: 5px;
      @media screen and (max-width: 530px) {
        width: 100%;
        font-size: 12px;
        line-height: 14px;
        margin-top: 20px;
      }
    }

    ::v-deep .v-btn,
    ::v-deep .v-input {
      width: 360px;
      max-width: 360px;
      @media screen and (max-width: 530px) {
        width: 100%;
        max-width: 100%;
      }
    }

    ::v-deep .v-input {
      @media screen and (max-width: 530px) {
        margin-bottom: 0;
      }
    }

    ::v-deep .v-btn {
      @media screen and (max-width: 530px) {
        margin-bottom: 20px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 530px) {
        padding: 0;
      }

      > button {
        width: 360px;
        height: 40px !important;
        background: #0071E3;
        border-radius: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 16px;
        margin-top: 1vh;
      }
    }
  }

  .mdi-reload {
    background: #FFFFFF;
    box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
    border-radius: 52px;
    width: 40px;
    height: 40px;
    top: -13px !important;
    right: -13px !important;

    &::before {
      content: url('../assets/images/captcha_icon.svg');
    }
  }

  .errors{
    p{
      margin-bottom: 0;
    }
  }
</style>
